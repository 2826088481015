<template>
    <div ref="hoverElement" class="popover-personinfo bs-popover-auto fade shadow" style="background-color: cornsilk!important;" role="tooltip">
        <div v-if="isLoaded" class="popover-header p-2" style="background-color: cornsilk!important;">
            <div class="fw-bold d-flex">
                <div style="font-size: smaller; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    <span v-if="personsRoles.length > 0">{{ personsRoles[0]?.Role }}</span>
                    <span v-else>{{ $t("Role") }}</span>
                </div>
            </div>
        </div>
        <h3 v-else class="popover-header p-2">
            {{ $t('Loading...') }}
        </h3>
        <div class="popover-body p-2" style="overflow-y: auto; max-height: 350px;">
            <template v-if="isLoaded">
                <template v-if="personsRoles.length > 0">
                    <p class="mb-1">{{ $t("Members of role in") }} <span class="fw-bold">{{ personsRoles[0]?.OrgUnit }}</span>:</p>
                    <ul class="mb-1">
                        <li v-for="p in personsRoles" :key="p.ID">{{ p.Name }}</li>
                    </ul>
                </template>                
                <span v-else>{{ $t("No persons with role") }}</span>
            </template>
            <template v-else>
                {{ $t('Loading...') }}
            </template>
        </div>
    </div>
</template>

<script lang="ts">
const PersonsRolescache = new Map<string, IPersonsRoleInfo[]>();

export interface IPersonsRoleInfo {
    ID: number;
    Name: string;
    OrgUnit_ID: number;
    Role_ID: number;
    Role: string;
    OrgUnit: string;
};

export interface IOrgUnitRolesHoverProps {
    roleId?: number;
    orgUnitId?: number;
}
</script>

<script setup lang="ts">
import { API } from 'o365-modules';
import { ref, watch, onMounted } from 'vue';
import { $t, logger } from 'o365-utils';
import { getOrCreateProcedure } from 'o365-modules';

const props = defineProps<IOrgUnitRolesHoverProps>();

const showContent = ref(false);
const isLoaded = ref(false);
const personsRoles = ref<IPersonsRoleInfo[] | null>(null);
const hoverElement = ref<HTMLElement | null>(null);

const procPersons = getOrCreateProcedure({
    id: "procPersons:" + crypto.randomUUID(),
    procedureName: "sstp_System_PersonsForRolesHoverDirective",
});

function hoverShow() {
    showContent.value = true;
    if (isLoaded.value && (personsRoles.value[0]?.OrgUnit_ID != props.orgUnitId || personsRoles.value[0]?.Role_ID != props.roleId)) {
        handleShow();
    }
}

function hoverHide() {
    showContent.value = false;
}

defineExpose({ hoverElement, hoverHide, hoverShow });

async function loadPersonsRolesData(roleId: number, orgUnitId: number) {
    const result = await procPersons.execute({ OrgUnit_ID: orgUnitId, Role_ID: roleId });
    return result.Table;
}

async function handleShow() {    
    isLoaded.value = false;
    personsRoles.value = null;
    if (props.roleId == null || props.orgUnitId == null) { return; }
    let persons = PersonsRolescache.get(cacheKeyFromProps(props));
    if (persons == null) {
        try {
            persons = await loadPersonsRolesData(props.roleId, props.orgUnitId);
            if (persons == null) { return; }
            PersonsRolescache.set(cacheKeyFromProps(props), persons);
        } catch (ex) {
            logger.error(ex);
            return;
        }
    }
    personsRoles.value = persons;
    isLoaded.value = true;
}

const cacheKeyFromProps = ({ orgUnitId, roleId }: IOrgUnitRolesHoverProps) => orgUnitId + "-" + roleId;

watch(() => props.roleId, () => {
    handleShow();
});

watch(() => props.orgUnitId, () => {
    handleShow();
});

onMounted(() => {
    handleShow();
});

</script>
